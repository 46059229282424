@use "_colors" as colors;

.dataTable-table {
  & > thead > tr > th {
    padding-right: 0rem !important;
    border-bottom: none !important;
  }
  .dataTable-sorter {
    color: colors.$dark;
    padding-right: 1.25em;
    text-decoration: none;
    &::before {
      border-top: 4px solid colors.$mid !important;
      bottom: 3px !important;
    }
    &::after {
      border-bottom: 4px solid colors.$mid !important;
      top: 1px !important;
    }
    &::before,
    &::after {
      opacity: 0.5 !important;
    }
  }

  .desc .dataTable-sorter::after {
    opacity: 1 !important;
  }
  .asc .dataTable-sorter::before {
    opacity: 1 !important;
  }
}
.dataTable-top,
.dataTable-bottom {
  padding: 0px !important;
  display: flex;
}

.dataTable-wrapper {
  &.no-footer .dataTable-container {
    border-bottom: none !important;
  }
  &.searchable {
    .dataTable-top,
    .dataTable-bottom {
      padding: 8px !important;
    }
  }
}

.dataTable-dropdown {
  color: colors.$mid;
  .dataTable-selector {
    border: 1px solid colors.$mid !important;
    border-radius: 0.25rem !important;
    background-color: colors.$lighter !important;
    color: colors.$dark !important;
  }
}
.dataTable-search {
  margin-left: auto;
  position: relative !important;
  .dataTable-input {
    border: 1px solid colors.$mid !important;
    border-radius: 0.25rem !important;
    background-color: colors.$lighter !important;
    padding-left: calc(0.75rem + 16px) !important;
    color: colors.$dark !important;
    &::placeholder {
      color: colors.$mid !important;
      opacity: 1 !important; /* Firefox */
    }
  }
  &:before {
    content: "" !important;
    position: absolute !important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23908c7d' class='bi bi-filter' viewBox='0 0 16 16'%3E%3Cpath d='M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z'/%3E%3C/svg%3E") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    top: 0.25rem !important;
    bottom: 0.25rem !important;
    left: 0.5rem !important;
    width: 16px !important;
    display: block !important;
  }
}
.dataTable-info {
  color: colors.$mid !important;
}
.dataTable-pagination {
  margin-left: auto;
  .dataTable-pagination-list {
    padding: 0px !important;
    list-style: none !important;
    display: flex !important;
    li {
      margin-left: 5px !important;
      &.active a,
      a:hover {
        margin-left: 5px !important;
        background-color: colors.$light !important;
        border-radius: 0.25rem !important;
        color: colors.$dark !important;
      }
      a {
        color: colors.$dark !important;
      }
    }
  }
}
