@use "_colors";

.choices {
  margin-bottom: 0px !important;
  &[data-type*="select-one"]:after {
    border-top-color: colors.$gray-400 !important;
  }

  &[data-type*="select-one"].is-open:after {
    border-color: transparent transparent colors.$gray-400 transparent !important;
    // margin-top: -7.5px;
  }

  .choices__heading {
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 30px;
  }

  .choices__inner {
    background-color: white;
    border-color: colors.$gray-400;
    min-height: calc(3.5rem + var(--bs-border-width) * 2);
  }

  &[data-type*="select-one"] .choices__inner {
    padding-bottom: 0.325rem !important;
    padding-top: 1.625rem !important;
    font-size: 1.2rem;
    line-height: 1.25rem;
    .choices__list--single {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }

  &[data-type*="select-multiple"] .choices__inner {
    padding-bottom: 0rem !important;
    padding-top: 1.625rem !important;
    padding-left: 0.75rem !important;

    .choices__input[type="search"] {
      margin-top: -6px;
      margin-bottom: -6px;
      font-size: 1.2rem;
      padding: 0px;
      width: 1ch;
      background-color: transparent;
      &::placeholder {
        color: colors.$gray-400;
      }
    }
    .choices__item {
      padding: 1px 10px !important;
      display: inline-flex !important;
      margin-bottom: 2px;
      margin-right: 2px;
    }
    .choices__item--selectable,
    .is-highlighted {
      & > .area-icon,
      & > .happiness-icon {
        margin-left: -9px;
      }
    }
    .choices__button {
      margin: 0 -8px 0 6px;
      padding-left: 10px;
    }
  }

  .area-icon,
  .happiness-icon {
    padding-left: 24px;
    span {
      margin: -1px;
    }
  }

  &.form-select-lg + .choices__list {
    // height: 200px;
    // padding-bottom: 0.325rem !important;
    // padding-top: 1.625rem !important;
    // font-size: 1.25rem;
  }

  .choices__list--dropdown,
  .choices__list[aria-expanded] {
    z-index: 1002;
  }

  .choices__list--dropdown .choices__placeholder {
    display: none;
  }

  .choices__list--dropdown .choices__input[type="search"] {
    background-color: colors.$gray-100 !important;
  }

  .choices__item.is-highlighted:not(.is-selected) {
    background-color: colors.$gray-400 !important;
    border-color: colors.$gray-400 !important;
  }

  .has-no-choices {
    color: colors.$gray-400 !important;
  }

  &.has-selection {
    .choices__input[type="search"]::placeholder {
      color: transparent !important;
    }
  }

  .choices__list .choices__item--choice.choices__item.is-selected {
    background-color: colors.$primary;
    color: colors.$white;
  }
}

.form-floating .choices.opened {
  & + label {
    z-index: 2;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  }
}

.scroll-for-more .choices .choices__list--dropdown:after {
  content: "scroll for more options";
  background-color: colors.$lighter;
  border-top: 1px solid colors.$lightish;
  color: colors.$mid;
  display: block;
  text-align: center;
  font-size: small;
  padding: 0.25rem;
}

.choices__list--dropdown .choices__item--selectable,
.choices__list[aria-expanded] .choices__item--selectable {
  padding-right: 10px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}
